/* Live */
export const appId = "FE76631E-7763-4BC9-B137-46D08E6707F2";

export const apiUrl = "https://ccpl.ninjaos.com/api/";
export const apiUrlV2 = "https://ccpl.ninjaos.com/apiv2/";
export const apiUrlPro = "https://ccpl.ninjaos.com/ninjapro/";
export const apiUrlCtrng = "https://ccpl.ninjaos.com/cateringv2/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";
//export const baseUrl = "https://semmyscatering.promobuddy.asia/";
//export const baseUrl = "http://192.168.43.4:3002/";
export const baseUrl = "https://semmyscatering.com/";
export const timThumpUrl = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const mediaUrl = "https://ccpl.ninjaos.com/media/dev_team/";
export const blogImageUrl = "https://ccpl.ninjaos.com/media/dev_team/blog/";
export const tagImageUrl = "https://ccpl.ninjaos.com/media/dev_team/tag/";
export const proFolderUrl = "https://ccpl.ninjaos.com/media/dev_team/products/";



/*
export const apiUrl = "https://ccpl.ninjaos.com/development/api/";
export const apiUrlV2 = "https://ccpl.ninjaos.com/development/apiv2/";
export const apiUrlPro = "https://ccpl.ninjaos.com/development/ninjapro/";
export const apiUrlCtrng = "https://ccpl.ninjaos.com/development/cateringv2/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";
export const baseUrl = "https://semmyscatering.promobuddy.asia/";
//export const baseUrl = "http://192.168.43.4:3002/";
//export const baseUrl = "https://semmyscatering.com/";
export const timThumpUrl = "https://ccpl.ninjaos.com/development/timthumb.php?src=";
export const mediaUrl = "https://ccpl.ninjaos.com/development/media/dev_team/";
export const blogImageUrl = "https://ccpl.ninjaos.com/development/media/dev_team/blog/";
export const tagImageUrl = "https://ccpl.ninjaos.com/development/media/dev_team/tag/";
export const proFolderUrl = "https://ccpl.ninjaos.com/development/media/dev_team/products/";*/


export const stripeImage = "/img/stripe_logo.png";

export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId = "EB62AF63-0410-47CC-9464-038E796E28C4";
export const reservationId = "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";

export const CountryTxt = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany = "Semmys Catering";

//export const fbAppId = "1590378584482925";
//export const fbAppId = "617798506293424";

export const fbAppId = "4500456006681332";

export const stripeKey = "pk_test_hLfht4KEShHlACDG9mBfrPkv";
export const stripeEmail = "dev@jankosoft.com";
export const stripeReference = "semmyscatering";
export const stripeDesc = "My Checkout";
export const stripeCurrency = "SGD";
